import { render, staticRenderFns } from "./Widget.vue?vue&type=template&id=5d1752a5"
import script from "./Widget.vue?vue&type=script&lang=js"
export * from "./Widget.vue?vue&type=script&lang=js"
import style0 from "./Widget.vue?vue&type=style&index=0&id=5d1752a5&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Markdown: require('/usr/app/components/markdown/Markdown.vue').default,Button: require('/usr/app/components/button/Button.vue').default,Picture: require('/usr/app/components/picture/picture.vue').default,WidgetApp: require('/usr/app/components/widget/App.vue').default,WidgetText: require('/usr/app/components/widget/Text.vue').default})

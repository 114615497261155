
import device from "@/mixins/device.js";
export default {
  name: "BannersWidget",
  mixins: [device],
  props: {
    banner: {
      type: Object,
    },
  },
};

import { render, staticRenderFns } from "./NumberSections.vue?vue&type=template&id=218dbda3"
import script from "./NumberSections.vue?vue&type=script&lang=js"
export * from "./NumberSections.vue?vue&type=script&lang=js"
import style0 from "./NumberSections.vue?vue&type=style&index=0&id=218dbda3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsNumberCard: require('/usr/app/components/cards/NumberCard.vue').default})


import lang from "@/mixins/lang.js";
import dynamicPagesParentsSlugs from "@/mixins/dynamic-pages-parents-slug";

export default {
  name: "ModulesSlim",
  mixins: [lang, dynamicPagesParentsSlugs],
  props: {
    /**
     * @param {endpoint} - içerik verilerinin url sorgularını sağlar.
     * Vue lifecycle `fetch` sırasında @param {this.$axios} ve @param {endpoint} kullanarak
     * Tüm veriler @param {endpointData} değişkenine atanır.
     */
    section: {
      type: Object,
    },
    endpoint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      endpointData: null,
      getEndpoint: null,
    };
  },
  async mounted() {
    this.getEndpoint = await this.endpoint;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!!this.endpoint) {
        try {
          const response = await this.$axios.get(`${this.endpoint}`);
          this.endpointData = response.data.data;
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};


import lang from "@/mixins/lang.js";

export default {
  name: "ProgressWave",
  mixins: [lang],
  props: {
    card: {
      type: Object,
    },
  },
  computed: {
    percentage() {
      return "height:" + (Math.round(this.card.dolulukOrani) + 20) + "%";
    },
  },
};


import qs from "qs";
import lang from "@/mixins/lang";
import SEO from "@/mixins/seo.js";

export default {
  layout: "home",
  mixins: [lang, SEO],
  data() {
    return {
      pageData: {},
      seo: {},
      pageTitle: "",
    };
  },

  async fetch() {
    try {
      const queryParams = qs.stringify(
        {
          locale: this.currentLang ?? "tr",
          populate: {
            heroSearchSection: {
              fields: ["*"],
              populate: [
                "search",
                "widgetRight.buttons.parent.parent.parent.parent",
                "widgetBottom.endpoint.parent.parent.parent.parent",
                "button",
                "background",
              ],
            },
            seo: {
              fields: ["*"],
              populate: "metaImage",
            },
            content: {
              on: {
                "sections.content-section": {
                  fields: "*",
                  populate: "endpoint",
                },
                "sections.two-column-content-section": {
                  fields: "*",
                  populate: "content.endpoint",
                },
                "sections.two-column-content-with-widget": {
                  fields: "*",
                  populate: [
                    "content.endpoint",
                    "widget.widget.buttons",
                    "widget.widget.logo",
                    "widget.widget.endpoint",
                  ],
                },
                "sections.services-section": {
                  fields: "*",
                  populate: [
                    "featuredServicesButtons",
                    "featuredServicesGroup.hizmetler.parent.parent.parent.parent",
                  ],
                },
                "sections.banner-section": {
                  fields: ["*"],
                  populate: [
                    "banners.button",
                    "banners.image",
                    "banners.widget.buttons",
                    "banners.widget.endpoint",
                    "banners.widget.logo",
                  ],
                },
                "sections.levels-section": {
                  fields: "*",
                },
                "sections.info-card-section": {
                  fields: "*",
                  populate: ["cards.image"],
                },
                "sections.number-card-section": {
                  fields: "*",
                  populate: "cards",
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      const { data } = await this.$axios.get(`anasayfa/?${queryParams}`);

      // Extract the relevant data from the response
      this.pageData = await data?.data?.attributes;
      this.seo = (await this.pageData?.seo) ?? null;
      this.pageTitle = (await this.pageData?.title) ?? null;
    } catch (error) {
      console.log(error);
    }
  },
};

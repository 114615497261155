
import lang from "@/mixins/lang.js";

export default {
  name: "CardsBanner",
  mixins: [lang],
  props: {
    card: {
      type: Object,
    },
  },
};

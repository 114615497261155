
export default {
  name: "SectionsLevelSections",
  props: {
    section: {
      type: Object,
    },
  },
  data() {
    return {
      content: [],
    };
  },
  async mounted() {
    try {
      const response = await Promise.race([
        this.$axios.get(`/iski/baraj/listesi`),
        new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error("Request timed out: /iski/baraj/listesi"));
          }, 60000); // Timeout duration in milliseconds (60 seconds)
        }),
      ]);
      const { data } = response;
      // Extract the relevant data from the response
      this.content = data?.data;
    } catch (error) {
      console.log(error);
    }
  },
};


import dynamicPagesParentsSlugs from "@/mixins/dynamic-pages-parents-slug";
export default {
  name: "SectionsTwoColumnContentWithWidgetSections",
  mixins: [dynamicPagesParentsSlugs],
  props: {
    section: {
      type: Object,
      required: true,
    },
    filterslug: {
      type: String,
    },
  },
  data() {
    return {
      getEndpoint: null,
    };
  },
  async mounted() {
    this.getEndpoint =
      (await this.section?.endpoint?.data?.attributes?.endpoint) ?? null;
  },
};


import lang from "@/mixins/lang.js";
import { dateMixin } from "@/mixins/date.js";
import { utils } from "@/mixins/utils.js";
export default {
  name: "CardsSlim",
  mixins: [lang, utils, dateMixin],
  props: {
    card: {
      type: Object,
    },
    dynamicParentSlug: {
      type: String,
    },
  },
};

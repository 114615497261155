
import device from "@/mixins/device";

export default {
  name: "ServicesSections",
  mixins: [device],
  props: {
    section: {
      type: Object,
    },
    background: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      featuredServicesGroupSlider: {
        arrows: false,
        dots: false,
        infinite: false,
        focusOnSelect: false,
        speed: 500,
        swipeToSlide: true,
        slidesToScroll: 1,
        accessibility: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.1,
            },
          },
        ],
      },
    };
  },
  methods: {
    icons(icon) {
      switch (icon) {
        case "pen_to_square":
          return "fa-solid fa-pen-to-square";
          break;
        case "droplet":
          return "fa-solid fa-droplet";
          break;
        case "scroll":
          return "fa-solid fa-scroll";
          break;
        case "circle_plus":
          return "fa-solid fa-circle-plus";
          break;
        case "angle_down":
          return "fas fa-angle-down";
          break;
        case "angle_left":
          return "fas fa-angle-left";
          break;
        case "angle_right":
          return "fas fa-angle-right";
          break;
        case "angle_up":
          return "fas fa-angle-up";
          break;
        case "arrow_down":
          return "fas fa-arrow-down";
          break;
        case "arrow_down_to_line":
          return "fas fa-arrow-to-bottom";
          break;
        case "arrow_up":
          return "fas fa-arrow-up";
          break;
        case "bars":
          return "fas fa-bars";
          break;
        case "bars_filter":
          return "fas fa-bars-filter";
          break;
        case "bus":
          return "fas fa-bus";
          break;
        case "calendar":
          return "fa-regular fa-calendar";
          break;
        case "camera_movie":
          return "fa-solid fa-camera-movie";
          break;
        case "check":
          return "fas fa-check";
          break;
        case "cloud":
          return "fas fa-cloud";
          break;
        case "facebook":
          return "fa-brands fa-facebook-f";
          break;
        case "file_lines":
          return "fas fa-file-lines";
          break;
        case "hand_holding_heart":
          return "fa-solid fa-hand-holding-heart";
          break;
        case "hands_holding_heart":
          return "fa-solid fa-hands-holding-heart";
          break;
        case "heart":
          return "fas fa-heart";
          break;
        case "instagram":
          return "fa-brands fa-instagram";
          break;
        case "minus":
          return "fas fa-minus";
          break;
        case "linkedin":
          return "fa-brands fa-linkedin";
          break;
        case "long_arrow_right":
          return "fas fa-long-arrow-right";
          break;
        case "paintbrush_pencil":
          return "fa-solid fa-paintbrush-pencil";
          break;
        case "phone":
          return "fas fa-phone";
          break;
        case "play":
          return "fas fa-play";
          break;
        case "search":
          return "fas fa-search";
          break;
        case "search_plus":
          return "fas fa-search-plus";
          break;
        case "square_arrow_up_right":
          return "fa-light fa-arrow-up-right-from-square";
          break;
        case "times":
          return "fas fa-times";
          break;
        case "tiktok":
          return "fa-brands fa-tiktok";
          break;
        case "youtube":
          return "fa-brands fa-youtube";
          break;
        case "twitter":
          return "fa-brands fa-x-twitter";
      }
    },
  },
};
